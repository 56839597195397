import { FC, useContext, useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { Signature } from '../interfaces/Signature';
import { Maintenance } from '../interfaces/Stylesheet';
import { TicketContext } from '../contexts/TicketContext';
import { CustomerFormContext } from '../contexts/CustomerFormContext';
import { StylesheetContext } from '../contexts/StylesheetContext';
import { LoadingContext } from '../contexts/LoaderContext';
import { SignatureContext } from '../contexts/SignatureContext';

import { getTicketIdFromLocalStorage, setTicketIdInLocalStorage } from '../helpers/LocalStorage';
import { calculateInitialPage, errorUrls, initTypes, } from '../services/RedirectService';

import Language from '../helpers/Language';
import { setAppointmentId } from '../services/Analytics';
import { isInMaintenanceMode } from '../services/MaintenanceService';
import StylesheetService from '../services/StylesheetService';
import TicketService from '../services/TicketService';
import SignatureService from '../services/SignatureService';
import { initializeChat } from '../services/ChatService';

interface InitializationProps {
  initType: string;
}

const Initialization: FC<InitializationProps> = ({ initType }) => {
  const [redirect, setRedirect] = useState<string | undefined>(undefined);
  const { setTicket } = useContext(TicketContext);
  const { setCustomer } = useContext(CustomerFormContext);
  const { setSignature } = useContext(SignatureContext);
  const { setStylesheets } = useContext(StylesheetContext);
  const { setIsLoading } = useContext(LoadingContext);
  const { id } = useParams<{ id?: string }>();

  const initializeSelfservice = async (id: string): Promise<void> => {
    Sentry.setTag('rssHashId', id);
    Sentry.setUser({ id: id });
    const stylesheetService = new StylesheetService();

    const globalStylesheet = await stylesheetService.getStylesheet(
      import.meta.env.VITE_GLOBAL_STYLESHEET
    ).catch(() => undefined);

    if (globalStylesheet) {
      const maintenance: Maintenance | null = globalStylesheet[Language.getLanguage()]?.maintenance;

      if (maintenance && isInMaintenanceMode(maintenance)) {
        setStylesheets(globalStylesheet);
        setRedirect(errorUrls.maintenance);
        setIsLoading(false);
        return;
      }
    }

    const ticket = await new TicketService().getTicket(id).catch(() => undefined);
    let brandStylesheet = undefined;
    let signature: Signature | undefined = undefined;

    setAppointmentId(ticket?.workOrder.appointment.id);

    if (initType === initTypes.signature && !!id && !!ticket) {
      signature = await new SignatureService().getSignature(id).catch(() => undefined);
      if (signature) {
        setSignature(signature);
      }
    }

    if (ticket) {
      brandStylesheet = await stylesheetService.getStylesheet(
        ticket.workOrder.workTypeId
      ).catch(() => undefined);
    }

    if (globalStylesheet) {
      setStylesheets(globalStylesheet, brandStylesheet);
    }

    if (ticket && brandStylesheet) {
      setTicket(ticket);

      if (ticket.customer) {
        setCustomer(ticket.customer);
      }

      initializeChat(ticket, brandStylesheet[Language.getLanguage()]);

      setTicketIdInLocalStorage(id);
    }

    const activeStylesheet = brandStylesheet?.[Language.getLanguage()];
    const redirectTo = calculateInitialPage({ ticket, stylesheet: activeStylesheet, signature, initType });

    setRedirect(redirectTo);
    setIsLoading(false);
  };

  useEffect(() => {
    const ticketId = id || getTicketIdFromLocalStorage();

    if (ticketId) {
      initializeSelfservice(ticketId);
    } else {
      setRedirect(errorUrls.ticketNotFound);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!!redirect && <Navigate to={redirect} />}
    </>
  );
};

export default Initialization;
