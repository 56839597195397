import { FC, useContext } from 'react';

import style from './ChatLink.module.scss';
import { TicketContext } from '../../contexts/TicketContext';
import { startChat } from '../../services/ChatService';
import Button from '../Button/Button';

const ChatLink: FC = (): JSX.Element | null => {
  const { ticket } = useContext(TicketContext);

  if (!ticket) {
    return null;
  }

  return (
    <span className={style.chatItem}>
      <img alt="whatsapp" src="/assets/chat.svg" />
      <Button variant="text" onClick={startChat} text="Start live chat" />
    </span>
  );
};

export default ChatLink;
